import React, {Component} from 'react'
import './StreetRunner.css'

import ScreenShot1 from './streetRunner0.jpg'
import ScreenShot2 from './streetRunner1.jpg'
import ScreenShot3 from './streetRunner2.jpg'
import ScreenShot4 from './streetRunner3.jpg'
import ScreenShot5 from './streetRunner4.jpg'

export default class StreetRunner extends Component{
    constructor() {
        super();
        this.currentState=0;
        this.state = { index : 0 };
        this.screenShots = [ScreenShot1, ScreenShot2, ScreenShot3,ScreenShot4,ScreenShot5];
    }

    changeMainImage(newIndex) {
        this.clearAll();
        this.currentState = newIndex;
        console.log(this.currentState);
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
        this.setState({index: newIndex});
    }

    changeMainImageDown(){
        this.clearAll();
        if(this.currentState > 0){
            this.currentState = this.currentState-1;
        }
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";

        this.setState({index : this.currentState});
    }

    changeMainImageUp(){
        this.clearAll();
        if(this.currentState < 4){
            this.currentState = this.currentState+1;
        }
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index : this.currentState});
    
    }

    clearAll(){
        document.getElementById("image0").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image1").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image2").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image3").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image4").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("imageB0").style.backgroundColor = "gray";
        document.getElementById("imageB1").style.backgroundColor = "gray";
        document.getElementById("imageB2").style.backgroundColor = "gray";
        document.getElementById("imageB3").style.backgroundColor = "gray";
        document.getElementById("imageB4").style.backgroundColor = "gray";
    }

    render(){
        var currentScreenShot = this.screenShots[this.state.index];
        return(
            <div className="informationContentBackground">

                <div className="gameTitleBackground">
                    <h1 className="gameTitleFullDescription-StreetRunner">Street Runner</h1>
                    <h2 className="gameDescriptionReleaseDate">Release Date: 05/24/20</h2>          
                </div>
                
                <img id="highlightPicture" className="mainPicture" src={currentScreenShot} alt="Screen_Shot"></img>
               
                <div className="centeredDots">
                   <div className="arrowLeft" onClick={(e) => this.changeMainImageDown()}></div>
                    <div className="dotsContainer">
                    <button style={{backgroundColor: "white"}} id="image0" className="btn-container" onClick={(e) => this.changeMainImage(0)}></button>
                    <button id="image1" className="btn-container" onClick={(e) => this.changeMainImage(1)}></button>
                    <button id="image2" className="btn-container" onClick={(e) => this.changeMainImage(2)}></button>
                    <button id="image3" className="btn-container" onClick={(e) => this.changeMainImage(3)}></button>
                    <button id="image4" className="btn-container" onClick={(e) => this.changeMainImage(4)}></button>
                    </div>
                   <div className="arrowRight" onClick={(e) => this.changeMainImageUp()}></div>
                </div>

                <div className="centered">
                    <button style={{backgroundColor: "white"}} id="imageB0" className="btn-container" onClick={(e) => this.changeMainImage(0)}>
                        <img className ="mini-image" src={ScreenShot1}></img>
                    </button>
                    <button id="imageB1" className="btn-container" onClick={(e) => this.changeMainImage(1)}> 
                        <img className ="mini-image" src={ScreenShot2}></img>
                    </button>
                    <button id="imageB2" className="btn-container" onClick={(e) => this.changeMainImage(2)}> 
                        <img className ="mini-image"  src={ScreenShot3}></img>
                    </button>
                    <button id="imageB3" className="btn-container" onClick={(e) => this.changeMainImage(3)}> 
                        <img className ="mini-image"  src={ScreenShot4}></img>
                    </button>
                    <button id="imageB4" className="btn-container" onClick={(e) => this.changeMainImage(4)}> 
                        <img className ="mini-image"  src={ScreenShot5}></img>
                    </button>
                </div>   

                <div class="textAlign">
                    <a class='animated-arrow' href="https://play.google.com/store/apps/details?id=com.DadHatGames.StreetRunner&hl=en&gl=US">
                        <span class='the-arrow -left'>
                            <span class='shaft-other'></span>
                        </span>
                        <span class='main'>
                        <span class='text'>
                            Download on Google Play
                        </span>
                        <span class='the-arrow -right'>
                            <span class='shaft'></span>
                        </span>
                        </span>
                    </a>
                </div>

                <div className="gameSummary-side-street">
                    <div className="gameSummary-header">
                        <h3 className="gameSummaryTitle">Summary</h3>
                    </div>
                    <div className="gameSummary-Content">
                        <p className="gameSummaryContent-SmallSum">Join Pixel Man in his attempt to do one of the most daring stunts in Pixel City! Jumping through traffic! Run on top of cars, jump over cars and try to see how long you can survive Pixel City.</p>
                    </div>
            
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">About the Game</h2>
                    </div>
                    <div className="aboutGame-content">
                        <h4 className="aboutGameHeaders">GAMEPLAY</h4>
                        <p className="aboutGameDescription">Street runner is a endless runner where you play as Pixel Man, your goal is to tap the screen to try and jump or run over as many cars as possible.</p>
                        <h4 className="aboutGameHeaders">LEADERBOARD</h4>
                        <p className="aboutGameDescription">See how you stack up against your friends and players around the world!</p>
                    </div>
                    
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">Legal</h2>
                    </div>
                    <div className="aboutGame-content">
                        
                        <h1 className="legalLinks"><a className="legalLinks" href="/StreetRunner/license">License and Attribution</a></h1>
                        
                        <h1 className="legalLinks"><a className="legalLinks" href="/StreetRunner/terms">Terms and Conditions</a></h1>
                        
                    </div>
                </div>

                <div className="contactUs">
                    <h1>Contact us at dadhatgames@gmail.com with any questions or requests!</h1>
                </div>
            </div>

      
        )
    }
}