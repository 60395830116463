export const MenuItemsLeft = [
    {
        title: 'Games',
        url: '/games',
        cName: 'nav-links'
    },
    {
        title: 'Mobile Games',
        url: '/mobile',
        cName: 'nav-links'
    }
]

export const MenuItemsRight =[
    {
        title: 'Other Products',
        url: '/other',
        cName: 'nav-links'
    },
    {
        title: 'About Us',
        url: '/aboutus',
        cName: 'nav-links'
    }
]

export const MenuItemsAll =[
    {
        title: 'Games',
        url: '/games',
        cName: 'nav-links'
    },
    {
        title: 'Mobile Games',
        url: '/mobile',
        cName: 'nav-links'
    },
    {
        title: 'Other Products',
        url: '/other',
        cName: 'nav-links'
    },
    {
        title: 'About Us',
        url: '/aboutus',
        cName: 'nav-links'
    }
]