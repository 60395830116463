import React, {Component} from 'react'
import ApocalypseXSummary from '../../Games/Summaries/ApocalypseX/ApocalypseXSummary'
import StreetRunnerSummary from '../../Games/Summaries/StreetRunner/StreetRunnerSummary'
import PolyPreserveSummary from '../../Games/Summaries/PolyPreserve/PolyPreserveSummary'
import './MobilePage.css'
import logo from '../../../images/logo1.png'

class MobilePage extends Component{
    render(){
        return(
            <div>
                <div>
                    <img className = "mainLogo" src={logo} alt="Dad Hat Games"></img>
                </div>
                <PolyPreserveSummary/>
                <ApocalypseXSummary/>
                <div className="bottomSpacer">
                    <StreetRunnerSummary/>
                </div>
            </div>
        )
    }
}
export default MobilePage