import React, {Component} from 'react'
import './PolyPreserveSummary.css'

class PolyPreserveSummary extends Component{
    render(){
        return(
            <div className="card-side-polypreserve">
                <a href="/PolyPreserve" className="card-header-polypreserve"></a>
                <div className="card-content-polypreserve">
                    <div>
                        <div className="mainInfo-polypreserve">
                            <h3 className ="gameTitle-polypreserve renameTitleSmall">PolyPreserve</h3>
                            <h3 className ="gameTitle-polypreserve renameTitleLarge">PolyPreserve: Idle Animal Sanctuary</h3>
                            <h5 className ="gameReleaseDate-polypreserve">Release Date: October, 2021</h5>
                        </div>
                        <div className="inLinePrice-polypreserve">
                            <h4 className="price-polypreserve">Price: Free!</h4>
                            <a href='https://play.google.com/store/apps/details?id=com.DadHatGames.Polyzoo'>
                                <img className="storeLink-polypreserve" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                            </a>
                        </div>
                        <div className="summaryText-polypreserve">
                            <h5>Summary:
                                <p>Enrich island's full of life while unlocking unique animals, upgrading their habitats and learning the secrets of PolyPreserve. 
                                    Come have a relaxing stay while making new animal friends in the PolyPreserve!
                                </p>
                            </h5>
                        </div>
                    </div>
                </div>
                
                <div className="bottomLink">
                        <h4 className="price-polypreserve-bottom">Price: 0.99 USD</h4>
                        <a href='https://play.google.com/store/apps/details?id=com.DadHatGames.Polyzoo'>
                            <img className="storeLink-polypreserve-bottom" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                        </a>
                </div>

                <footer className="card-footer-polypreserve">
                    <a className="action-link" href="/PolyPreserve">
                        Full Description
                    </a>
                </footer>
            </div>
        )
    }
}
export default PolyPreserveSummary