import React, {Component} from 'react'
import './ApocalypseXLicense.css'

export default class ApocalypseXLicense extends Component{
    render(){
        return(
            <div className="legalBackground-Apoc">
                <div className="legalElement">
                    <h1>Credits:</h1>
                </div>
                <div className="legalElement">
                    <h1>Hippo Games</h1>
                    <a href="https://vk.com/hippogames">
                        Mechs and Aliens
                    </a>
                </div>
                <div className="legalElement">
                    <h1>D.F.Y. STUDIO</h1>
                    <a href="https://dfy_studio.artstation.com/projects/aY1YWq">
                        Sci-FI UI Pack
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Fenerax Studios</h1>
                    <a href="https://assetstore.unity.com/publishers/32730/">
                        Easy to use joystick package
                    </a>
                </div>
                <div className="legalElement">
                    <h1>futureramen</h1>
                    <a href="https://www.futureramen.com/">
                        In game music
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Valery Oleynikov</h1>
                    <a href="https://soundcloud.com/valery-oleynikov">
                        Weapon sound effects
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Zuhria A</h1>
                    <a href="https://www.gameart2d.com/license.html">
                        Tilesets
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Icons8</h1>
                    <a href=" https://icons8.com/">
                        Some icons used for this project
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Google Play Services</h1>
                    <a href="https://developer.android.com/distribute/play-services">
                        Google Play was used for player name in this project
                    </a>
                </div>
            </div>
        )
    }
}