import React, {Component} from 'react'
import './PolyPreserveLicense.css'

export default class PolyPreserveLicense extends Component{
    render(){
        return(
            <div className="legalBackground-PolyPreserve">
                <div className="legalElement">
                    <h1>Credits:</h1>
                </div>
                <div className="legalElement">
                    <h1>Moodkie</h1>
                    <a href="https://assetstore.unity.com/packages/tools/utilities/easy-save-the-complete-save-data-serializer-system-768">
                        Easy Save
                    </a>
                </div>
                <div className="legalElement">
                    <h1>DENTED PIXEL</h1>
                    <a href="https://assetstore.unity.com/packages/tools/animation/leantween-3595">
                        LeanTween
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Archanor VFX</h1>
                    <a href="https://assetstore.unity.com/packages/vfx/particles/polygon-arsenal-109286">
                        Polygon Arsenal
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Synty</h1>
                    <a href="https://syntystore.com/products/polygon-nature-pack">
                        POLYGON - Nature Pack
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Synty</h1>
                    <a href="https://syntystore.com/collections/frontpage/products/polygon-fantasy-kingdom">
                        POLYGON - Fantasy Kingdom
                    </a>
                </div>
                <div className="legalElement">
                    <h1>BOXOPHOBIC</h1>
                    <a href="https://assetstore.unity.com/packages/vfx/shaders/polyverse-skies-low-poly-skybox-shaders-104017">
                        Polyverse Skies
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Alexander Ameye</h1>
                    <a href="https://assetstore.unity.com/packages/vfx/shaders/stylized-water-for-urp-162025">
                        Stylized Water For URP
                    </a>
                </div>
                <div className="legalElement">
                    <h1>EpiXR Games</h1>
                    <a href="https://assetstore.unity.com/packages/3d/props/clothing/accessories/ultimate-low-poly-hats-bundle-176911">
                        Ultimate Low Poly Hats Bundle
                    </a>
                </div>
            </div>
        )
    }
}