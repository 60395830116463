import React, {Component} from 'react'
import './ApocalypseXTerms.css'

export default class ApocalypseXTerms extends Component{
    render(){
        return(
            <div className="legalBackground-Apoc">
                <div className="legalElement">
                    <h1>PRIVACY POLICY</h1>
                    <h3>Dad Hat Games, ApocalypseX</h3>
                    <h3>Privacy Policy Dad Hat Games </h3>
                    <h3>Last Updated: May 24th 2020</h3>
                </div>
                <div className="legalElement">
                    <p>Dad Hat Games has developed this Privacy Policy to help you understand how Dad Hat Games collects, uses and discloses information Dad Hat Games may collect from you and/or your device (computer, phone or other mobile device), or that you may provide to Dad Hat Games, while using Dad Hat Games websites and Dad Hat Games apps. By using the Dad Hat Games Services, you agree to how Dad Hat Games collects, uses and discloses information that is collected from you. If you are under 18 years old, you acknowledge that your legal guardian has consented to this Privacy Policy. If you, or your legal guardian do not accept or understand this Privacy Policy, please do not use the Dad Hat Games Services.</p>
                    <p>If you have questions or complaints regarding Dad Hat Games Privacy Policy or practices, please contact Dad Hat Games at dadhatgames@gmail.com</p>
                </div>
                <div className="legalElement">
                    <h4>1.  What Data Is being Collected</h4>
                    <p>
                        In order for Photon, the networking solution we use, to correctly assign you to a region Photon may gather information about your location to properly assign you to a sever
                    </p>
                    <a href="https://www.photonengine.com/pun">
                        To read more about Photon please click here
                    </a>
                </div>
                <div className="legalElement">
                    <h4>2. Multplayer Features</h4>
                    <p>
                        Dad Hat Games is not responsible for player behavior during multiplayer
                    </p>
                </div>
            </div>
        )
    }
}