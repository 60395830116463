import React, {Component} from 'react'
import './PolyPreserve.css'

import ScreenShot1 from './PolyPreserve1.jpg'
import ScreenShot2 from './PolyPreserve2.jpg'
import ScreenShot3 from './PolyPreserve3.jpg'
import ScreenShot4 from './PolyPreserve4.jpg'
import ScreenShot5 from './PolyPreserve5.jpg'

export default class PolyPreserve extends Component{
    constructor() {
        super();
        this.currentState=0;
        this.state = { index : 0 };
        this.screenShots = [ScreenShot1, ScreenShot2, ScreenShot3,ScreenShot4,ScreenShot5];
    }

    changeMainImage(newIndex) {
        this.clearAll();
        this.currentState = newIndex;
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index: newIndex});
    }

    changeMainImageDown(){
        this.clearAll();
        if(this.currentState > 0){
            this.currentState = this.currentState-1;
        }
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index : this.currentState});
    }

    changeMainImageUp(){
        this.clearAll();
        if(this.currentState < 4){
            this.currentState = this.currentState+1;
        }
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index : this.currentState});
    
    }

    clearAll(){
        document.getElementById("image0").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image1").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image2").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image3").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image4").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("imageB0").style.backgroundColor = "gray";
        document.getElementById("imageB1").style.backgroundColor = "gray";
        document.getElementById("imageB2").style.backgroundColor = "gray";
        document.getElementById("imageB3").style.backgroundColor = "gray";
        document.getElementById("imageB4").style.backgroundColor = "gray";
    }

    render(){
        var currentScreenShot = this.screenShots[this.state.index];
        return(
            <div className="informationContentBackground">

                <div className="gameTitleBackground">
                    <h1 className="gameTitleFullDescription">PolyPreserve: Idle Animal Sanctuary</h1>
                    <h2 className="gameDescriptionReleaseDate">Release Date: 10/21/22</h2>          
                </div>
                
                <img id="highlightPicture" className="mainPicture-portrait" src={currentScreenShot} alt="Screen_Shot"></img>
               
                <div className="centeredDots">
                   <div className="arrowLeft" onClick={(e) => this.changeMainImageDown()}></div>
                    <div className="dotsContainer">
                    <button style={{backgroundColor: "white"}} id="image0" className="btn-container" onClick={(e) => this.changeMainImage(0)}></button>
                    <button id="image1" className="btn-container" onClick={(e) => this.changeMainImage(1)}></button>
                    <button id="image2" className="btn-container" onClick={(e) => this.changeMainImage(2)}></button>
                    <button id="image3" className="btn-container" onClick={(e) => this.changeMainImage(3)}></button>
                    <button id="image4" className="btn-container" onClick={(e) => this.changeMainImage(4)}></button>
                    </div>
                   <div className="arrowRight" onClick={(e) => this.changeMainImageUp()}></div>
                </div>

                <div className="centered">
                    <button id="imageB0" style={{backgroundColor: "white"}} className="btn-container" onClick={(e) => this.changeMainImage(0)}>
                        <img className ="mini-image-portrait" src={ScreenShot1}></img>
                    </button>
                    <button id="imageB1" className="btn-container" onClick={(e) => this.changeMainImage(1)}> 
                        <img className ="mini-image-portrait" src={ScreenShot2}></img>
                    </button>
                    <button id="imageB2" className="btn-container" onClick={(e) => this.changeMainImage(2)}> 
                        <img className ="mini-image-portrait"  src={ScreenShot3}></img>
                    </button>
                    <button id="imageB3" className="btn-container" onClick={(e) => this.changeMainImage(3)}> 
                        <img className ="mini-image-portrait"  src={ScreenShot4}></img>
                    </button>
                    <button id="imageB4" className="btn-container" onClick={(e) => this.changeMainImage(4)}> 
                        <img className ="mini-image-portrait"  src={ScreenShot5}></img>
                    </button>
                </div>   

                <div class="textAlign">
                    <a class='animated-arrow' href="https://play.google.com/store/apps/details?id=com.DadHatGames.Polyzoo">
                        <span class='the-arrow -left'>
                            <span class='shaft-other'></span>
                        </span>
                        <span class='main'>
                        <span class='text'>
                            Download on Google Play!
                        </span>
                        <span class='the-arrow -right'>
                            <span class='shaft'></span>
                        </span>
                        </span>
                    </a>
                </div>

                <div className="gameSummary-side">
                    <div className="gameSummary-header">
                        <h3 className="gameSummaryTitle">Summary</h3>
                    </div>
                    <div className="gameSummary-Content">
                    <p className="gameSummaryContent">PolyPreserve is a relaxing game about building up your animal preserve to make sure your animals are as happy as can be!
                           </p>
                        <p className="gameSummaryContent">Enrich island habitats while unlocking unique animals in this zen idle game!</p>
                    </div>
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">About the Game</h2>
                    </div>
                        <div className="aboutGame-content">
                            <h4 className="aboutGameHeaders">20+ Discoverable Species</h4>
                            <p className="aboutGameDescription">Each biome has new species to discover and learn more about with their animal facts! Can you fill up your biomes?</p>

                            <h4 className="aboutGameHeaders">75+ visual upgrades to enhance your habitats</h4>
                            <p className="aboutGameDescription">Make sure your animals are living their best lives by upgrading their habitats and making PolyPreserve look more like .</p>

                            <h4 className="aboutGameHeaders">Customize your animals with thousands of unique options</h4>
                            <p className="aboutGameDescription">Each animal has a wide variety of different hats and skins that can be purchased. Mix and match or create a uniform style, the choice is yours!</p>

                            <h4 className="aboutGameHeaders">Originally composed soundtrack</h4>
                            <p className="aboutGameDescription">Make sure to relax and listen to our original soundtrack while making your habitats look as cool as possible.</p>

                            <h4 className="aboutGameHeaders">Keep your islands clean</h4>
                            <p className="aboutGameDescription">Removing trash around your islands not only makes them eyecatching, but provides a nice bonus too!</p>

                            <h4 className="aboutGameHeaders">Collect hidden messages and unlock the secret behind PolyPreserve</h4>
                            <p className="aboutGameDescription">Gather all 24 unique notes each with a unique message helping you learn more about what the PolyPreserve really is.</p>
                    </div>
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">Real World Impact</h2>
                    </div>
                    <div className="aboutGame-content">
                    <h4 className="aboutGameHeaders">Help Dad Hat Games </h4>
                            <p className="aboutGameDescription">50% of all cosmetic sales will be donated to Wildlife conservation charities.</p>
                    </div>
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">Legal</h2>
                    </div>
                    <div className="aboutGame-content">
                        
                        <h1 className="legalLinks"><a className="legalLinks" href="/PolyPreserve/license">Credits</a></h1>
                        
                        <h1 className="legalLinks"><a className="legalLinks" href="/PolyPreserve/terms">Terms and Conditions</a></h1>
                        
                    </div>
                </div>

                <div className="contactUs">
                    <h1>Contact us at dadhatgames@gmail.com with any questions or requests!</h1>
                </div>
            </div>

      
        )
    }
}