import React, {Component} from 'react'
import './BattleBallLicense.css'

export default class BattleBallLicense extends Component{
    render(){
        return(
            <div className="legalBackground-BattleBall">
                <div className="legalElement">
                    <h1>Credits:</h1>
                </div>
                <div className="legalElement">
                    <h1>Explosive</h1>
                    <a href="https://assetstore.unity.com/packages/3d/animations/rpg-character-mecanim-animation-pack-63772">
                        RPG Character Mecanim Animation Pack
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Gallant Games</h1>
                    <a href="https://assetstore.unity.com/packages/tools/input-management/incontrol-14695">
                        InControl
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Potion Audio</h1>
                    <a href="https://assetstore.unity.com/packages/audio/ambient/ambience-sounds-vol-1-175145">
                        Ambience Sounds Vol. 1
                    </a>
                </div>
                <div className="legalElement">
                    <h1>BOXOPHOBIC</h1>
                    <a href="https://assetstore.unity.com/packages/vfx/shaders/free-skybox-extended-shader-107400">
                        FREE Skybox Extended Shader
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Broken Vector</h1>
                    <a href="https://assetstore.unity.com/packages/3d/props/exterior/low-poly-fence-pack-61661">
                        Low Poly Fence Pack
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Stegobubbles</h1>
                    <a href="https://assetstore.unity.com/packages/3d/characters/humanoids/hypercasual-simple-characters-159307">
                        HYPERCASUAL - Simple Characters
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Kevin Iglesias</h1>
                    <a href="https://assetstore.unity.com/packages/3d/animations/mega-animations-pack-162341">
                        Mega Animations Pack
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Synty</h1>
                    <a href="https://syntystore.com/products/polygon-nature-pack">
                        POLYGON - Nature Pack
                    </a>
                </div>
                <div className="legalElement">
                    <h1>EpiXR Games</h1>
                    <a href="https://assetstore.unity.com/packages/3d/props/clothing/accessories/ultimate-low-poly-hats-bundle-176911">
                        Ultimate Low Poly Hats Bundle
                    </a>
                </div>
                <div className="legalElement">
                    <h1>GamesAreLife</h1>
                    <a href="https://assetstore.unity.com/packages/3d/props/3d-balls-collection-103428">
                        3D Balls Collection
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Photon</h1>
                    <a href="https://www.photonengine.com/">
                        Photon Multiplayer  
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Sudtipos</h1>
                    <a href="https://www.fontsquirrel.com/fonts/bubblegum-sans">
                        Bubblegum Sans Font
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Divide by Zero</h1>
                    <a href="https://www.dafont.com/action-jackson.font">
                        Action Jackson
                    </a>
                </div>
            </div>
        )
    }
}