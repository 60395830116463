import React, {Component} from 'react'
import './Argio.css'

class Argio extends Component{
    render(){
        return(
            <div className="card-side-argio">
                <a href="https://schichko-argio.web.app/" className="card-header-argio"></a>
                <div className="card-content-argio">
                    <div>
                        <div className="mainInfo-argio">
                            <h3 className ="gameTitle-argio">arrg.io</h3>
                            <h5 className ="gameReleaseDate-argio">Release Date: April, 2020</h5>
                        </div>
                        <div className="inLinePrice-argio">
                            <a href="https://schichko-argio.web.app/">Play Now!</a>
                        </div>
                    </div>
                    <div className="summaryText-argio">
                        <h5>Summary:
                            <p>Choose your ship and take to the high seas in arrg.io! A battle royal where you sail around the map collecting coins, 
                                buying upgrades and becoming the most feared pirate on the high seas! 
                            </p>
                            <p>Side note: This is the first game I ever released!</p>
                        </h5>
                    </div>
                </div>
                <footer className="card-footer-argio">
                    <a className="action-link" href="https://schichko-argio.web.app/">
                        Play Now!
                    </a>
                </footer>
            </div>
        )
    }
}
export default Argio