import React, {Component} from 'react'
import './ApocalyseX.css'

import ScreenShot1 from './apocScreens1.jpg'
import ScreenShot2 from './apocScreens2.jpg'
import ScreenShot3 from './apocScreens3.jpg'
import ScreenShot4 from './apocScreens4.jpg'
import ScreenShot5 from './apocScreens5.jpg'

export default class ApocalyseX extends Component{
    constructor() {
        super();
        this.currentState=0;
        this.state = { index : 0 };
        this.screenShots = [ScreenShot1, ScreenShot2, ScreenShot3,ScreenShot4,ScreenShot5];
    }

    changeMainImage(newIndex) {
        this.clearAll();
        this.currentState = newIndex;
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index: newIndex});
    }

    changeMainImageDown(){
        this.clearAll();
        if(this.currentState > 0){
            this.currentState = this.currentState-1;
        }
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index : this.currentState});
    }

    changeMainImageUp(){
        this.clearAll();
        if(this.currentState < 4){
            this.currentState = this.currentState+1;
        }
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index : this.currentState});
    
    }

    clearAll(){
        document.getElementById("image0").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image1").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image2").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image3").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image4").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("imageB0").style.backgroundColor = "gray";
        document.getElementById("imageB1").style.backgroundColor = "gray";
        document.getElementById("imageB2").style.backgroundColor = "gray";
        document.getElementById("imageB3").style.backgroundColor = "gray";
        document.getElementById("imageB4").style.backgroundColor = "gray";
    }

    render(){
        var currentScreenShot = this.screenShots[this.state.index];
        return(
            <div className="informationContentBackground">

                <div className="gameTitleBackground">
                    <h1 className="gameTitleFullDescription">Apocalyse-X</h1>
                    <h2 className="gameDescriptionReleaseDate">Release Date: 06/20/20</h2>          
                </div>
                
                <img id="highlightPicture" className="mainPicture" src={currentScreenShot} alt="Screen_Shot"></img>
               
                <div className="centeredDots">
                   <div className="arrowLeft" onClick={(e) => this.changeMainImageDown()}></div>
                    <div className="dotsContainer">
                    <button style={{backgroundColor: "white"}} id="image0" className="btn-container" onClick={(e) => this.changeMainImage(0)}></button>
                    <button id="image1" className="btn-container" onClick={(e) => this.changeMainImage(1)}></button>
                    <button id="image2" className="btn-container" onClick={(e) => this.changeMainImage(2)}></button>
                    <button id="image3" className="btn-container" onClick={(e) => this.changeMainImage(3)}></button>
                    <button id="image4" className="btn-container" onClick={(e) => this.changeMainImage(4)}></button>
                    </div>
                   <div className="arrowRight" onClick={(e) => this.changeMainImageUp()}></div>
                </div>

                <div className="centered">
                    <button id="imageB0" style={{backgroundColor: "white"}} className="btn-container" onClick={(e) => this.changeMainImage(0)}>
                        <img className ="mini-image" src={ScreenShot1}></img>
                    </button>
                    <button id="imageB1" className="btn-container" onClick={(e) => this.changeMainImage(1)}> 
                        <img className ="mini-image" src={ScreenShot2}></img>
                    </button>
                    <button id="imageB2" className="btn-container" onClick={(e) => this.changeMainImage(2)}> 
                        <img className ="mini-image"  src={ScreenShot3}></img>
                    </button>
                    <button id="imageB3" className="btn-container" onClick={(e) => this.changeMainImage(3)}> 
                        <img className ="mini-image"  src={ScreenShot4}></img>
                    </button>
                    <button id="imageB4" className="btn-container" onClick={(e) => this.changeMainImage(4)}> 
                        <img className ="mini-image"  src={ScreenShot5}></img>
                    </button>
                </div>   

                <div class="textAlign">
                    <a class='animated-arrow' href="https://play.google.com/store/apps/details?id=com.dadhatgames.ApocalypseX&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <span class='the-arrow -left'>
                            <span class='shaft-other'></span>
                        </span>
                        <span class='main'>
                        <span class='text'>
                            Purchase from Google Play
                        </span>
                        <span class='the-arrow -right'>
                            <span class='shaft'></span>
                        </span>
                        </span>
                    </a>
                </div>

                <div className="gameSummary-side">
                    <div className="gameSummary-header">
                        <h3 className="gameSummaryTitle">Summary</h3>
                    </div>
                    <div className="gameSummary-Content">
                        <p className="gameSummaryContent">Apocalypse-X is an endless twin stick shooter game. You are stationed on Zeta base, the prime target for an attack! 
                                    Defend Zeta base by yourself or with up to two friends.</p>
                        <p className="gameSummaryContent">The objective is simple, yet hard to master, survive wave after wave of aliens! See what mech and weapon combos work best for you and your team!</p>
                    </div> 
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">About the Game</h2>
                    </div>
                        <div className="aboutGame-content">
                            <h4 className="aboutGameHeaders">TEAM UP WITH 2 OTHER FRIENDS</h4>
                            <p className="aboutGameDescription">Create your own lobby and open it to play with everyone or set it to private for only you and your friends.</p>

                            <h4 className="aboutGameHeaders">GO AT IT ALONE</h4>
                            <p className="aboutGameDescription">Want to have all the glory for yourself? No problem, see how long you can defend Zeta base on your own.</p>

                            <h4 className="aboutGameHeaders">3 DIFFERENT CHARACTERS TO PLAY</h4>
                            <p className="aboutGameDescription">Play as the Scout, The Defender or The Dreadnought. All 3 characters have different stats so try to see what combos work best for you and your team!</p>

                            <h4 className="aboutGameHeaders">MIX AND MATCH WEAPONS AND ARMOR</h4>
                            <p className="aboutGameDescription">You have the ability to have a weapon on each hand, maybe a Gatling gun on one hand and a laser rifle on the other. The choice is up to you! Don't forget about your armor either, you're going to want all the protection you can get against the alien horde.</p>

                            <h4 className="aboutGameHeaders">VARIETY OF ALIENS</h4>
                            <p className="aboutGameDescription">The alien horde is composed of many different aliens, worms, spiders, and strange abstract creatures nobody has seen before!</p>
                    </div>
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">Future Updates</h2>
                    </div>
                    <div className="aboutGame-content">
                        <ul>
                            <li className="futureUpdates">More Weapons</li>
                            <li className="futureUpdates">More Armor</li>
                        </ul>
                    </div>
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">Legal</h2>
                    </div>
                    <div className="aboutGame-content">
                        
                        <h1 className="legalLinks"><a className="legalLinks" href="/ApocalypseX/license">Credits</a></h1>
                        
                        <h1 className="legalLinks"><a className="legalLinks" href="/ApocalypseX/terms">Terms and Conditions</a></h1>
                        
                    </div>
                </div>

                <div className="contactUs">
                    <h1>Contact us at dadhatgames@gmail.com with any questions or requests!</h1>
                </div>
            </div>

      
        )
    }
}