import React, {Component} from 'react'
import './StreetRunnerSummary.css'

class StreetRunnerSummary extends Component{
    render(){
        return(
            <div className="card-side-streetRunner">
                <a href="/StreetRunner" className="card-header-streetRunner"></a>
                <div className="card-content-streetRunner">
                    <div>
                        <div className="mainInfo-streetRunner">
                            <h3 className ="gameTitle-streetRunner">Street Runner</h3>
                            <h5 className ="gameReleaseDate-streetRunner">Release Date: May, 2020</h5>
                        </div>
                        <div className="inLinePrice-streetRunner">
                            <h4 className="price-streetRunner">Price: Free!</h4>
                            <a href='https://play.google.com/store/apps/details?id=com.DadHatGames.StreetRunner&hl=en&gl=US'>
                                <img className="storeLink-streetRunner" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                            </a>
                        </div>
                    </div>
                    <div className="summaryText-streetRunner">
                        <h5>Summary:
                            <p>Join Pixel Man in his attempt to do one of the most daring stunts in Pixel City! 
                                Jumping through traffic! Run on top of cars, jump over cars and try to see how long you can survive Pixel City.
                            </p>
                        </h5>
                    </div>
                </div>

                <div className="bottomLink">
                    <h4 className="price-streetRunner-bottom">Price: Free!</h4>
                    <a href='https://play.google.com/store/apps/details?id=com.DadHatGames.StreetRunner&hl=en&gl=US'>
                        <img className="storeLink-streetRunner-bottom" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                    </a>
                </div>

                <footer className="card-footer-streetRunner">
                    <a className="action-link" href="/StreetRunner">
                        Full Description
                    </a>
                </footer>
            </div>
        )
    }
}
export default StreetRunnerSummary