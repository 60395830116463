import React, {Component} from 'react'
import './OtherPage.css'

import Argio from '../../Games/Summaries/Argio/Argio'

import logo from '../../../images/logo1.png'
import swag1 from '../../../images/swag/swag1.png'
import swag2 from '../../../images/swag/swag2.png'
import swag3 from '../../../images/swag/swag3.png'
import swag4 from '../../../images/swag/swag4.png'
import swag5 from '../../../images/swag/swag5.png'
import swag6 from '../../../images/swag/swag6.png'
import swag7 from '../../../images/swag/swag7.png'
import swag8 from '../../../images/swag/swag8.png'

class OtherPage extends Component{
    render(){
        return(
            <div>
                <div>
                    <img className = "mainLogo" src={logo} alt="Dad Hat Games"></img>
                </div>
    
                <div class="textAlign-other">
                    <a class='animated-arrow-other' href="https://www.redbubble.com/people/dadhatgames/shop?artistUserName=dadhatgames&asc=u">
                        <span class='the-arrow-other -left-other'>
                            <span class='shaft-other'></span>
                        </span>
                        <span class='main-other'>
                        <span class='text-other'>
                            Check out our Merch!
                        </span>
                        <span class='the-arrow-other -right-other'>
                            <span class='shaft-other'></span>
                        </span>
                        </span>
                    </a>
                </div>

{/* 
                <h1 className="merchTitle">Check out our Merch!</h1> */}

                <a href="https://www.redbubble.com/people/dadhatgames/shop?artistUserName=dadhatgames&asc=u">
                    <div className="container image-grid">
                        <div className="column image-column">
                            <div></div>
                            <div></div>
                            <img className= "swagItem" src={swag8}></img>
                            <img className= "swagItem" src={swag4}></img>
                            <img className= "swagItem" src={swag2}></img>
                            <img className= "swagItem" src={swag3}></img>
                            <img className= "swagItem" src={swag7}></img> 
                            <img className= "swagItem" src={swag5}></img>
                            <img className= "swagItem" src={swag6}></img>
                            <img className= "swagItem" src={swag1}></img>
                        </div>
                    </div>
                </a>

                <h1 className="merchTitle">Browser Games</h1>
                <div className="bottomSpacer">
                    <Argio></Argio>
                </div>
            </div>
        )
    }
}
export default OtherPage