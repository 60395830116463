import './App.css';
import Navbar from './components/Navbar/Navbar'
import OtherPage from './components/Webpage/Other/OtherPage';
import MobilePage from './components/Webpage/Mobile/MobilePage';
import GamesPage from './components/Webpage/Games/GamesPage';
import AboutUs from './components/Webpage/AboutUs/AboutUs';

import ApocalyseX from './components/Games/FullDescription/ApocalypseX/ApocalypseX'
import ApocalpyseXLicense from './components/Games/FullDescription/ApocalypseX/Legal/ApocalypseXLicense'
import ApocalpyseXTerms from './components/Games/FullDescription/ApocalypseX/Legal/ApocalypseXTerms'

import StreetRunner from './components/Games/FullDescription/StreetRunner/StreetRunner'
import StreetRunnerLicense from './components/Games/FullDescription/StreetRunner/Legal/StreetRunnerLicense'
import StreetRunnerTerms from './components/Games/FullDescription/StreetRunner/Legal/StreetRunnerTerms'

import BattleBall from './components/Games/FullDescription/BattleBall/BattleBall'
import BattleBallLicense from './components/Games/FullDescription/BattleBall/Legal/BattleBallLicense'
import BattleBallTerms from './components/Games/FullDescription/BattleBall/Legal/BattleBallTerms'

import PolyPreserve from './components/Games/FullDescription/PolyPreserve/PolyPreserve'
import PolyPreserveLicense from './components/Games/FullDescription/PolyPreserve/Legal/PolyPreserveLicense'
import PolyPreserveTerms from './components/Games/FullDescription/PolyPreserve/Legal/PolyPreserveTerms'

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
  
  return (
    <div>
        <Navbar/>
        <Router>
          <Switch>
            <Route path="/" exact component={GamesPage}></Route>
            <Route path="/games" component={GamesPage}></Route>
            <Route path="/mobile" component={MobilePage}></Route>
            <Route path="/other" component={OtherPage}></Route>
            <Route path="/aboutus" component={AboutUs}></Route>
            <Route path="/ApocalypseX" exact component={ApocalyseX}></Route>
            <Route path="/ApocalypseX/license" exact component={ApocalpyseXLicense}></Route>
            <Route path="/ApocalypseX/terms" exact component={ApocalpyseXTerms}></Route>
            <Route path="/StreetRunner" exact component={StreetRunner}></Route>
            <Route path="/StreetRunner/license" exact component={StreetRunnerLicense}></Route>
            <Route path="/StreetRunner/terms" exact component={StreetRunnerTerms}></Route>
            <Route path="/BattleBall" exact component={BattleBall}></Route>
            <Route path="/BattleBall/license" exact component={BattleBallLicense}></Route>
            <Route path="/BattleBall/terms" exact component={BattleBallTerms}></Route>
            <Route path="/PolyPreserve" exact component={PolyPreserve}></Route>
            <Route path="/PolyPreserve/license" exact component={PolyPreserveLicense}></Route>
            <Route path="/PolyPreserve/terms" exact component={PolyPreserveTerms}></Route>
          </Switch>
        </Router> 
    </div>
  );
}

export default App;
