import React, {Component} from 'react'
import logo from '../../../images/logo1.png'
import profilePic from '../../../images/profilePic.png'
import './AboutUs.css'
class AboutUs extends Component{
    render(){
        return(
            <div>
                <img className = "profilePicture" src={profilePic} alt="Profile Pic"></img>
                <div className = "aboutUsInto">
                    <h2 className="aboutUsTitleHeading">About Us</h2>
                    <p className="aboutUsText">
                        My name is Jake Schichko, owner of Dad Hat Games, and I am a Software Developer with a passion for game engineering and design. Professionally I work as a Software Engineer at Intercept Games on an untitled project using Unreal Engine 5.
                    </p>

                    <h2 className="aboutUsTitleHeading">My Game Development Story</h2>
                    <p className="aboutUsText">
                        I have always been passionate about making and playing video games.
                        I mainly work with Unity in my personal development time, and I've been using Unreal Engine in my current role as a Software Developer at Intercept Games. 
                        Although I started playing around with real game development in my college years. I found a real passion for it in March 2020 when the Covid-19 pandemic hit. 
                        I had just graduated college and finally had time to sink time into projects. Within my first year of game development I had developed and released 4 games:
                    </p>  
                    <ul className="aboutUsList">
                        <li className="aboutUsListText">Arg.io - A multiplayer WebGL battle royal game where each player is in control of a pirate ship</li>
                        <li className="aboutUsListText">Street Runner - A endless runner game which I released on the google play store</li>
                        <li className="aboutUsListText">Apocalypse-X - A online cooperative twin stick shooter </li>
                        <li className="aboutUsListText">Battle Ball - A 3d online dodgeball game</li>
                    </ul>

                    <p className="aboutUsText">
                        Since then I have released another project Polypreserve, which I worked on with 2 close friends of mine.
                        Now I have recently started on a more long term muliplayer project which I am really excited to dive deeper into.
                    </p> 
                 
                    <p className="aboutUsText"> 
                        The best advice that I can give someone getting started in Unity is try to pick a few small goals or tools to practice
                        for each project you are working on, and if possible save others for later. For example with Arg.io I wanted to focus on       
                        getting a basic understanding of PUN and the Unity Particle System, not too concerned about Ads, lighting or other tools I was not used to. 
                        Later when working on Apocalypse-X I decided that lighting would be a big part of the project so I focused on learning a ton about Unity's Globabl illumination system.
                        Using the Particle System, Google Play features, and generally developing on Android did not take as much time since I had experience with them in prior projects so I was able to really dedicte time to learning new features.
                    </p>
                </div>

                <div className = "aboutUsInto">
                    <h2 className="aboutUsTitleHeading">Currently Playing</h2>

                    <h3 className="gameTitleHeading">Avatar Frontiers of Pandora</h3>
                    <p className="aboutUsText">
                        Beautiful visuals, simple yet fun gameplay akin to the Farcry games with a suprisingly fun crafting system. Story expands on the Avatar universe in an intresting way too!
                    </p>  

                    <h3 className="gameTitleHeading">Valheim</h3>
                    <p className="aboutUsText">
                        I am loving Valheim so far! I am playing with 6 friends and the combonation of base building, souls like combat and exploration blends together perfectly.
                    </p>  

                    <h3 className="gameTitleHeading">Helldivers 2</h3>
                    <p className="aboutUsText">
                        Just dove in to protecting super earth but its been great so far. Reminds me a lot of Vermentide/Deep Rock. 
                    </p>  
 
                </div>

                <h1 className="favoriteTitle">My Favorite...</h1>
                <div className="favoriteSection">
                    <div className="favoriteBlock">
                        <h2 className="aboutUsFavorite">Songs:</h2>
                        <ul className="favoriteList">
                            <li className="favoriteText">SUPERBLOOM - Misterwives</li>
                            <li className="favoriteText">Incredible True Story - Logic</li>
                            <li className="favoriteText">Limelight(feat. R O Z E S) - Just A Gent</li>
                            <li className="favoriteText">everybody dies - J. Cole</li>
                            <li className="favoriteText">Pressure - Paramore</li>
                            <li className="favoriteText">Million Bucks - Smallpools</li>
                            <li className="favoriteText">Tribe - Bas</li>
                            <li className="favoriteText">Little Talks - Of Monsters and Men</li>
                            <li className="favoriteText">Under Pressure - Logic</li>
                            <li className="favoriteText">Freaks And Geeks - Childish Gambino</li>
                        </ul>
                    </div>

                    <div className="favoriteBlock">
                        <h2 className="aboutUsFavorite">Games:</h2>
                        <ul className="favoriteList">
                            <li className="favoriteText">Fallout New Vegas</li>
                            <li className="favoriteText">Halo 3</li>
                            <li className="favoriteText">Last of Us</li>
                            <li className="favoriteText">Red Dead Redemption 2</li>
                            <li className="favoriteText">Legend of Zelda - Breath of the Wild</li>
                            <li className="favoriteText">Bioshock Infinite</li>
                            <li className="favoriteText">Mass Effect 2</li>
                            <li className="favoriteText">God of War</li>
                            <li className="favoriteText">Starbound</li>
                            <li className="favoriteText">Dark Souls 3</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default AboutUs