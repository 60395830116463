import React, {Component} from 'react'
import {MenuItemsLeft,MenuItemsRight,MenuItemsAll} from "./MenuItems"
import {Button} from "../Button"
import logoHat from '../../images/hatwithmelt.png'
import './Navbar.css'
import ReactDOM from 'react-dom';
class Navbar extends Component{
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleClickOutside, true);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleClickOutside, true);
    }
    
    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
    
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({clicked: false})
        }
    }

    state = {clicked:false}

    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    }
    

    render(){
        return(
            
            <nav className="NavbarItems">
                 <ul className ={this.state.clicked ? 'nav-menu-left active' : 'nav-menu-left'}>
                    {MenuItemsLeft.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url}>
                                {item.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>

                <img className="navbar-logo" src={logoHat}></img>
                <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>

                <ul className ={this.state.clicked ? 'nav-menu-right active' : 'nav-menu-right'}>
                    {MenuItemsRight.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url}>
                                {item.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>

                <ul className ={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItemsAll.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url}>
                                {item.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        )
    }

}

export default Navbar