import React, {Component} from 'react'
import './BattleBall.css'

import ScreenShot1 from './battleBall1.jpg'
import ScreenShot2 from './battleBall2.jpg'
import ScreenShot3 from './battleBall3.jpg'
import ScreenShot4 from './battleBall4.jpg'
import ScreenShot5 from './battleBall5.jpg'

export default class BattleBall extends Component{
    constructor() {
        super();
        this.currentState=0;
        this.state = { index : 0 };
        this.screenShots = [ScreenShot1, ScreenShot2, ScreenShot3,ScreenShot4,ScreenShot5];
    }

    changeMainImage(newIndex) {
        this.clearAll();
        this.currentState = newIndex;
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index: newIndex});
    }

    changeMainImageDown(){
        this.clearAll();
        if(this.currentState > 0){
            this.currentState = this.currentState-1;
        }
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index : this.currentState});
    }

    changeMainImageUp(){
        this.clearAll();
        if(this.currentState < 4){
            this.currentState = this.currentState+1;
        }
        document.getElementById("image"+this.currentState).style.backgroundColor = "white";
        document.getElementById("imageB"+this.currentState).style.backgroundColor = "white";
       
        this.setState({index : this.currentState});
    
    }

    clearAll(){
        document.getElementById("image0").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image1").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image2").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image3").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("image4").style.backgroundColor = "rgba(76, 85, 88, 0.534)";
        document.getElementById("imageB0").style.backgroundColor = "gray";
        document.getElementById("imageB1").style.backgroundColor = "gray";
        document.getElementById("imageB2").style.backgroundColor = "gray";
        document.getElementById("imageB3").style.backgroundColor = "gray";
        document.getElementById("imageB4").style.backgroundColor = "gray";
    }

    render(){
        var currentScreenShot = this.screenShots[this.state.index];
        return(
            <div className="informationContentBackground">

                <div className="gameTitleBackground">
                    <h1 className="gameTitleFullDescription">Battle Ball</h1>
                    <h2 className="gameDescriptionReleaseDate">Release Date: 03/15/21</h2>          
                </div>
                
                <img id="highlightPicture" className="mainPicture" src={currentScreenShot} alt="Screen_Shot"></img>
               
                <div className="centeredDots">
                   <div className="arrowLeft" onClick={(e) => this.changeMainImageDown()}></div>
                    <div className="dotsContainer">
                    <button style={{backgroundColor: "white"}} id="image0" className="btn-container" onClick={(e) => this.changeMainImage(0)}></button>
                    <button id="image1" className="btn-container" onClick={(e) => this.changeMainImage(1)}></button>
                    <button id="image2" className="btn-container" onClick={(e) => this.changeMainImage(2)}></button>
                    <button id="image3" className="btn-container" onClick={(e) => this.changeMainImage(3)}></button>
                    <button id="image4" className="btn-container" onClick={(e) => this.changeMainImage(4)}></button>
                    </div>
                   <div className="arrowRight" onClick={(e) => this.changeMainImageUp()}></div>
                </div>

                <div className="centered">
                    <button id="imageB0" style={{backgroundColor: "white"}} className="btn-container" onClick={(e) => this.changeMainImage(0)}>
                        <img className ="mini-image" src={ScreenShot1}></img>
                    </button>
                    <button id="imageB1" className="btn-container" onClick={(e) => this.changeMainImage(1)}> 
                        <img className ="mini-image" src={ScreenShot2}></img>
                    </button>
                    <button id="imageB2" className="btn-container" onClick={(e) => this.changeMainImage(2)}> 
                        <img className ="mini-image"  src={ScreenShot3}></img>
                    </button>
                    <button id="imageB3" className="btn-container" onClick={(e) => this.changeMainImage(3)}> 
                        <img className ="mini-image"  src={ScreenShot4}></img>
                    </button>
                    <button id="imageB4" className="btn-container" onClick={(e) => this.changeMainImage(4)}> 
                        <img className ="mini-image"  src={ScreenShot5}></img>
                    </button>
                </div>   

                <div class="textAlign">
                    <a class='animated-arrow' href="https://store.steampowered.com/app/1514660/Battle_Ball/">
                        <span class='the-arrow -left'>
                            <span class='shaft-other'></span>
                        </span>
                        <span class='main'>
                        <span class='text'>
                            Download on Steam!
                        </span>
                        <span class='the-arrow -right'>
                            <span class='shaft'></span>
                        </span>
                        </span>
                    </a>
                </div>

                <div className="gameSummary-side">
                    <div className="gameSummary-header">
                        <h3 className="gameSummaryTitle">Summary</h3>
                    </div>
                    <div className="gameSummary-Content">
                    <p className="gameSummaryContent">Battle Ball is a goofy online multiplayer game. Play with your team in a 3 v 3 game of loose-rules dodgeball meets twin-stick shooter. 
                    Play with your friends or knock them out by teaming up with random players!</p>
                        <p className="gameSummaryContent">Collect Dodgebucks and become the coolest Battler around!</p>
                    </div>
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">About the Game</h2>
                    </div>
                        <div className="aboutGame-content">
                            <h4 className="aboutGameHeaders">OLD SCHOOL 3 ON 3</h4>
                            <p className="aboutGameDescription">In Battle Ball you are part of a 3 person team with only one goal: knock out the other players as many times as you can within 3 minutes.</p>

                            <h4 className="aboutGameHeaders">READY UP</h4>
                            <p className="aboutGameDescription">Already have a team ready? No problem, you can host your own lobby and have friends join before finding an opposing team.</p>

                            <h4 className="aboutGameHeaders">DIP, DIVE AND DODGE</h4>
                            <p className="aboutGameDescription">In Battle Ball, it's not only important to be an ace when hitting people, it's also important to dodge the opposing teams balls to prevent them from scoring.</p>

                            <h4 className="aboutGameHeaders">EXPRESS YOURSELF</h4>
                            <p className="aboutGameDescription">Mix and match your favorite outfits, hats, and skins. With hundreds of combinations, there is something for everybody.</p>

                            <h4 className="aboutGameHeaders">OUTDOOR ENVIRONMENTS</h4>
                            <p className="aboutGameDescription">Get outside and smell the fresh air of two different maps.</p>

                            <h4 className="aboutGameHeaders">STEAM ACHIEVEMENTS</h4>
                            <p className="aboutGameDescription">Collect all 15 Steam Achievements to prove you are the ultimate battle baller!</p>
                    </div>
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">Future Updates</h2>
                    </div>
                    <div className="aboutGame-content">
                        <ul>
                            <li className="futureUpdates">General quality of life improvments</li>
                            <li className="futureUpdates">In game leaderboards</li>
                        </ul>
                    </div>
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">Publications</h2>
                    </div>
                    <div className="aboutGame-content">
                        
                        <h1 className="publicationsLinks"><a className="publicationsLinks" href="https://gameskeys.net/top-steam-games-to-lookout-for-in-march-2021/">Top steam games to lookout for in march 2021</a></h1>
                    
                    </div>
                </div>

                <div className="aboutGame-side">
                    <div className="aboutGame-header">
                        <h2 className="gameSummaryTitle">Legal</h2>
                    </div>
                    <div className="aboutGame-content">
                        
                        <h1 className="legalLinks"><a className="legalLinks" href="/BattleBall/license">Credits</a></h1>
                        
                        <h1 className="legalLinks"><a className="legalLinks" href="/BattleBall/terms">Terms and Conditions</a></h1>
                        
                    </div>
                </div>

                <div className="contactUs">
                    <h1>Contact us at dadhatgames@gmail.com with any questions or requests!</h1>
                </div>
            </div>

      
        )
    }
}