import React, {Component} from 'react'
import './StreetRunnerLicense.css'

export default class StreetRunnerLicense extends Component{
    render(){
        return(
            <div className="legalBackground">
                <div className="legalElement">
                    <h1>Credits:</h1>
                </div>
                <div className="legalElement">
                    <h1>Kenny.nl</h1>
                    <a href="https://www.kenney.nl/assets/pixel-vehicle-pack">
                        All character and vehicle assets used in this project
                    </a>
                </div>
                <div className="legalElement">
                    <h1>pixelsoftgames</h1>
                    <a href="http://www.pixelsoftgames.com/forums/">
                        Font used for most of this project
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Google Admob</h1>
                    <a href="https://developers.google.com/admob/terms">
                        Google Admob was used for the Ads in this project
                    </a>
                </div>
                <div className="legalElement">
                    <h1>Google Play Services</h1>
                    <a href="https://developer.android.com/distribute/play-services">
                        Google Play was used for the leaderboard in this project
                    </a>
                </div>
            </div>
        )
    }
}