import React, {Component} from 'react'
import './ApocalypseXSummary.css'

class ApocalypseXSummary extends Component{
    render(){
        return(
            <div className="card-side-apocalypse">
                <a href="/ApocalypseX" className="card-header-apocalypse"></a>
                <div className="card-content-apocalypse">
                    <div>
                        <div className="mainInfo-apocalypse">
                            <h3 className ="gameTitle-apocalypse">Apocalypse-X</h3>
                            <h5 className ="gameReleaseDate-apocalypse">Release Date: June, 2020</h5>
                        </div>
                        <div className="inLinePrice-apocalypse">
                            <h4 className="price-apocalypse">Price: 0.99 USD</h4>
                            <a href='https://play.google.com/store/apps/details?id=com.dadhatgames.ApocalypseX&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <img className="storeLink-apocalypse" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                            </a>
                        </div>
                        <div className="summaryText-apocalypse">
                            <h5>Summary:
                                <p>Apocalypse-X is an endless twin stick shooter game. You are stationed on Zeta base, the prime target for an attack! 
                                    Defend Zeta base by yourself or with up to two friends. The objective is simple, yet hard to master, survive wave after wave of aliens!</p>
                            </h5>
                        </div>
                    </div>
                </div>
                
                <div className="bottomLink">
                        <h4 className="price-apocalypse-bottom">Price: 0.99 USD</h4>
                        <a href='https://play.google.com/store/apps/details?id=com.dadhatgames.ApocalypseX&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img className="storeLink-apocalypse-bottom" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                        </a>
                </div>

                <footer className="card-footer-apocalypse">
                    <a className="action-link" href="/ApocalypseX">
                        Full Description
                    </a>
                </footer>
            </div>
        )
    }
}
export default ApocalypseXSummary