import React, {Component} from 'react'
import './BattleBallSummary.css'
import steamIcon from './steamlink.png'


class BattleBallSummary extends Component{
    render(){
        return(
            <div className="card-side-battleBall">
                <a href="/BattleBall" className="card-header-battleBall"></a>
                <div className="card-content-battleBall">
                    <div>
                        <div className="mainInfo-battleBall">
                            <h3 className ="gameTitle-battleBall">Battle Ball</h3>
                            <h5 className ="gameReleaseDate-battleBall">Release Date: March, 2021</h5>
                        </div>
                        <div className="inLinePrice-battleBall">
                            <h4 className="price-battleBall">Price: Free!</h4>
                            <a href='https://store.steampowered.com/app/1514660/Battle_Ball/'>
                                <img className="storeLink-battleBall" alt='Get it on Steam' src={steamIcon}/>
                            </a>
                        </div>
                        <div className="summaryText-battleBall">
                            <h5>Summary:
                                <p>Battle Ball is a goofy online multiplayer game. Play with your team in a 3 v 3 game of loose-rules dodgeball meets twin-stick shooter. 
                                    Play with your friends or knock them out by teaming up with random players!</p>
                            </h5>
                        </div>
                    </div>
                </div>
                
                <div className="bottomLink">
                    <h4 className="price-battleBall-bottom">Price: Free!</h4>
                    <a href='https://store.steampowered.com/app/1514660/Battle_Ball/'>
                        <img className="storeLink-battleBall-bottom" alt='Get it on Steam' src={steamIcon}/>
                    </a>
                </div>

                <footer className="card-footer-battleBall">
                    <a className="action-link" href="/BattleBall">
                        Full Description
                    </a>
                </footer>
            </div>
        )
    }
}
export default BattleBallSummary