import React, {Component} from 'react'
import './PolyPreserveTerms.css'

export default class PolyPreserveTerms extends Component{
    render(){
        return(
            <div className="legalBackground-PolyPreserve">
                <div className="legalElement">
                    <h1>PRIVACY POLICY</h1>
                    <h3>Dad Hat Games, PolyPreserve: Idle Animal Sanctuary</h3>
                    <h3>Privacy Policy Dad Hat Games </h3>
                    <h3>Last Updated: October 15th 2022</h3>
                </div>
                <div className="legalParagraph">
                    <p>Dad Hat Games has developed this Privacy Policy to help you understand how Dad Hat Games collects, uses and discloses information Dad Hat Games may collect from you and/or your device (computer, phone or other mobile device), or that you may provide to Dad Hat Games, while using Dad Hat Games websites and Dad Hat Games apps. By using the Dad Hat Games Services, you agree to how Dad Hat Games collects, uses and discloses information that is collected from you. If you are under 18 years old, you acknowledge that your legal guardian has consented to this Privacy Policy. If you, or your legal guardian do not accept or understand this Privacy Policy, please do not use the Dad Hat Games Services.</p>
                    <br/>
                    <p>If you have questions or complaints regarding Dad Hat Games Privacy Policy or practices, please contact Dad Hat Games at dadhatgames@gmail.com</p>
                </div>
                <div className="legalCommon">
                    <h3>1. What Data Is being Collected</h3>
                    <p className="legalParagraph">
                        If you watch advertisements within our services or those of our partners, they may also collect information about the advertisements served and how often you have viewed them, their location in the game and the identifier of your device. We and our partners may also collect information about your actions in relation to the advertisements, such as the page views, the time and the duration of the view.

                        In order to fully use our Services, it is necessary to provide the data listed below. Without this Data, we are not able to provide our Services in its full extent to you.
                        <ul>
                            <li>
                                <h4 className="legalBulletHeader">Use our Sevices:</h4>
                                <p>
                                    <ul>
                                        <li className="legalParagraph">Game data such as achieved game upgrades, cosmetic purchases, virtual currency and completed events.</li>
                                        <li className="legalParagraph">IP address and approximate location data (derived from your IP address)</li>
                                        <li className="legalParagraph">Mobile device identifiers such as your device ID advertising ID with your consent</li>
                                        <li className="legalParagraph">Device data and technical data such as device type and operating system, network connection type and mobile carrier, screen resolution, device language</li>
                                        <li className="legalParagraph">User preferences and settings, such as game language, settings regarding push-notifications and in-game sound and music.</li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <h4 className="legalBulletHeader">Make purchases in our Services:</h4>
                                <p className="legalParagraph">
                                    We do not collect or store any payment information such as your credit card number. When you make purchases within our Services, we only collect Data about purchase transaction types and spend you have made using virtual items in the Services.
                                </p>
                            </li>
                        </ul>
                    </p>
                    <h3>2. ADVERTISING NETWORKS</h3>
                    <p className="legalParagraph">
                        We use third-party software from Google to deliver advertising and content relevant to your interests.

                        Google may process your Data and may use cookies and other tracking technologies to collect information about your use of our Services as well as other serives on their stores.
                    </p>
                    <p className="legalParagraph">
                        This collected information includes, but is not limited to installation Data, in-app events, language, platform, device type, operating system version or device IDs such as advertising IDs and usage information.

                        These collect the Data described below for the purpose of providing personalized advertising:
                    </p>
                        <h4 className="legalParagraph">AdMob/Google | <a href="https://policies.google.com/privacy?hl=en">Privacy Policy</a></h4>
                        <p className="legalParagraph">Google Corporation, 1600 Amphitheatre Parkway in Mountain View, California, USA. Online identifiers, including cookie identifiers, internet protocol addresses and device identifiers; client identifiers</p>

                    <h3>3. DELETION OF YOUR DATA</h3>
                    <p className="legalParagraph">
                        No personal data is stored by Dad Hat Games. If you wish to delete any files for your game you can do so from your device and the Google play games app. If you wish to learn more about any data advertisements may be collecting please contact <a href="https://admob.google.com/home/request-consult/">Google Admob</a>
                    </p>
                    <h3>4. AGE LIMITS</h3>
                    <p className="legalParagraph"> 
                        If you are under the age of 16 please obtain the consent of your legal guardian. We do not knowingly collect or ask for personal information from children. 
                    
                        Since Dad Hat Games does not store any information, no information about children will be gathered. 
                    </p>
                    <h3>5. YOUR RIGHTS</h3>
                    <p className="legalParagraph">
                        In case you have questions about the Privacy Policy, please contact us via email at dadhatgames@gmail.com.
                    </p>
                    <h3>6. CHANGES TO THIS PRIVACY POLICY</h3>
                    <p className="legalParagraph">
                        We reserve the right to update this Privacy Policy, if necessary, in compliance with the applicable data protection regulations. This way, we can adapt it to the current legal requirements and take changes in our services into account, e.g. when introducing new Services. The most current version always applies to your visit.
                    </p>
                </div>
            </div>
        )
    }
}