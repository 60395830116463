import React, {Component} from 'react'
import './GamesPage.css'
import BattleBallSummary from '../../Games/Summaries/BattleBall/BattleBallSummary'

import logo from '../../../images/logo1.png'
class GamesPage extends Component{
    render(){
        return(
            <div>
                <div>
                    <img className = "mainLogo" src={logo} alt="Dad Hat Games"></img>
                </div>
                <BattleBallSummary/>
            </div>
        )
    }
}
export default GamesPage